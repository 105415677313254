<template>
  <div>
    <el-container>
      <el-row style="width: 100%">
        <el-col :span="6">
          <div class="task-top">
            <div class="select" style="margin-top: 5px; margin-left: 10px">
              <el-select
                v-model="DocumentType"
                placeholder="文献类型"
                style="width: 120px"
              >
                <el-option
                  v-for="item in DocumentTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                style="width: 150px"
                v-model="BeginTime"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="起始时间"
              >
              </el-date-picker>
              <el-date-picker
                style="width: 150px"
                v-model="EndTime"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="终止时间"
              >
              </el-date-picker>
            </div>
            <div
              style="
                width: 320px;
                margin-left: 10px;
                margin-top: 5px;
                display: flex;
                padding-right: 20px;
              "
            >
              <el-input
                v-model="searchinput"
                size="small"
                placeholder="请输入查找关键词"
              ></el-input>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-search"
                @click="search"
              >
                查询</el-button
              >
            </div>
          </div>
          <div class="task-list" v-loading="loading">
            <div
              style="
                margin-top: 5px;
                height: 550px;
                overflow: hidden;
                width: 100%;
              "
            >
              <!-- <div :class="[
                                currindex == index ? 'task-list-box1' : 'task-list-box',
                            ]" v-for="(item, index) in Tasklist" :key="item.id" @click="taskbtn(index, item.sourceID)">
                                <p style="margin-left: 20px">
                                    {{ item.title }}({{ item.createTime }})
                                </p>
                            </div> -->
              <el-table
                :data="Tasklist"
                height="100%"
                border
                ref="singleTable"
                highlight-current-row
                @row-click="rowClick"
              >
                <el-table-column prop="title" label="题名" width="120">
                </el-table-column>
                <!-- <el-table-column prop="documentType" label="文献类型" width="120">
                </el-table-column> -->
                <el-table-column label="文献类型" width="120">
                  <template slot-scope="scope">
                    <div
                      class="ellipsis"
                      :title="
                        scope.row.documentType
                          | documentType(DocumentTypeOption, scope.row)
                      "
                    >
                      <span>{{
                        scope.row.documentType
                          | documentType(DocumentTypeOption, scope.row)
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" label="提交时间">
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="task-bottom">
            <!-- 右侧页码控制 -->
            <el-pagination
              @current-change="SmallhandleCurrentChange"
              :current-page="SmallPageIndex"
              :page-size="SmallPageSize"
              small
              background
              layout="prev, pager, next"
              :total="SmallTotals"
            >
            </el-pagination>
          </div>
        </el-col>
        <el-col :span="18" style="padding-left:10px;">
          缩略图
          <div class="ctn-box-list">
            <div
              class="ctn-box-list-list"
              v-for="(item, index) in TaskDetailList"
              :key="item.id"
              @click="
                nexturl(
                  item.id,
                  item.pictureFullName,
                  item.sourceID,
                  index,
                  TaskDetailList
                )
              "
            >
              <el-image
                fit="contain"
                style="width: 100%; height: 90%"
                :src="`${baseurl}api/fileinfo/image/GetOriImageThumbnail?filename=${
                  item.pictureFullName
                }&ocrSource=${typevalue == 1 ? 1 : 2}`"
              ></el-image>
              <!-- <div class="list-bottom1" v-if="item.ocrFlag == 2">
                <i class="el-icon-check"></i>{{ item.checkingDate }}
              </div> -->
              <div class="list-bottom">进入详情</div>
            </div>
          </div>
          <div
            style="
              width: 98%;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="PageIndex"
              :page-sizes="[15, 20, 30, 50, 70]"
              :page-size="PageSize"
              prev-text="上一页"
              next-text="下一页"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="Totals"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </el-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SystemSinglepage",
  data() {
    return {
      Tasklist: [],
      TaskDetailList: [],
      SmallPageIndex: 1,
      SmallPageSize: 10,
      SmallTotals: 0,
      PageIndex: 1,
      PageSize: 15,
      Totals: 0,

      baseurl: this.baseURL,
      ocrSource: 1,
      loading: false,
      typevalue: "1",
      filevalue: "",
      searchinput: "",
      currindex: null, //当前页码
      sourceID: "", //选中的文献记录标识id
      currentRow: null,
      DocumentType: "",
      BeginTime: "",
      EndTime: "",
    };
  },
  computed: {
    ...mapState(["DocumentTypeOption"]),
  },
  mounted() {
    this.TaskNumber();
  },
  filters: {
    documentType(documentType, DocumentTypeOption, data) {
      let documentTypeName;
      if (documentType === 0) return (documentTypeName = data.documentTypeText);
      let index = DocumentTypeOption.findIndex(
        (item) => item.value === documentType
      );
      documentTypeName = index === -1 ? "" : DocumentTypeOption[index].label;
      return documentTypeName;
    },
  },
  methods: {
    //查询
    search() {
      (this.SmallPageIndex = 1), (this.loading = true);
      setTimeout(() => {
        this.loading = false;
      }, 500);
      this.TaskNumber();
    },
    //上传列表
    TaskNumber() {
      var requestData = {
        PageSize: this.SmallPageSize,
        PageIndex: this.SmallPageIndex,
        SourceID: this.searchinput,
        beginTime: this.BeginTime,
        endTime: this.EndTime,
      };
      if (this.DocumentType !== "") {
        requestData.documentType = this.DocumentType;
      }
      this.$request({
        url: "/api/proofread/user-center/document-list-dtos",
        method: "GET",
        headers: {
          accept: "text/plain",
        },
        params: requestData,
      })
        .then((res) => {
          this.Tasklist = res.items;
          this.SmallTotals = res.totalCount;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //右边每页记录数改变
    handleSizeChange(val) {
      this.PageSize = val;
      console.log(val);
      this.taskbtn(this.currindex, this.sourceID);
    },
    //右边列表翻页
    handleCurrentChange(val) {
      this.PageIndex = val;
      console.log(val);
      this.taskbtn(this.currindex, this.sourceID);
    },
    //页数
    SmallhandleCurrentChange(val) {
      this.SmallPageIndex = val;
      this.TaskNumber();
    },

    //行点击事件
    rowClick(row, column, event) {
      //this.taskName = row.title;
      //this.sourceID = row.sourceID;
      //this.GetTask(row.sourceID);
      this.taskbtn(this.currindex, row.sourceID);
      this.currentRow = row;
    },
    //改变颜色
    tableRowStyle({ row }) {
      let rowBackground = {};
      if (row.sourceID == this.sourceID) {
        rowBackground.background = "rgb(106, 214, 106)";
        return rowBackground;
      }
    },
    // 人物编号列表详情
    taskbtn(i, id) {
      this.currindex = i;
      this.sourceID = id;

      this.$request({
        url: "/api/proofread/user-center/document-ocr-dtos",
        method: "GET",
        headers: {
          accept: "text/plain",
        },
        params: {
          PageSize: this.PageSize,
          PageIndex: this.PageIndex,
          SourceID: this.sourceID,
          OcrSource: this.typevalue,
        },
      })
        .then((res) => {
          console.log("res", res);
          this.TaskDetailList = res.items;
          this.Totals = res.totalCount;
          this.sourceID = id;
          //this.PageIndex = 1;
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    // 跳转到校对页
    nexturl(id, name, sourceID, index, item) {
      //  item: JSON.stringify(item),
      const { href } = this.$router.resolve({
        path: "/proofreading/singlePageDetail",
        query: {
          id: id,
          sourceID: sourceID,
          index: index,
          ocrSource: 1,
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  width: 100%;
}

.card-title {
  width: 100%;
  height: 35px;
  background: rgb(245, 244, 244);
  display: flex;
  align-items: center;
}

.card-title span {
  margin-left: 20px;
  font-size: 13px;
}

/deep/.el-card__body {
  padding: 0;
  height: 100%;
}

.task {
  width: 350px;
  height: 100%;
  //   background: cornsilk;
}

.task-top {
  float: left;
  border-radius: 3px;
  width: 100%;
  height: 90px;
  border: 1px solid #ddd;
}

.task-top span {
  font-size: 15px;
  margin-top: 20px;
  margin-left: 20px;
}

.select {
  padding-right: 20px;
  margin-left: 20px;
  margin-top: 5px;
  display: flex;
}

.task-list {
  width: 100%;
  // height: 800px;
  border-radius: 3px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  cursor: pointer;
}

.task-list-box {
  margin-left: 20px;
  //width: 280px;
  height: 30px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 15px;
}

.task-list-box1 {
  margin-left: 20px;
  // width: 280px;
  height: 30px;
  background: rgb(106, 214, 106);
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 15px;
  border-radius: 5px;
  color: white;
}

.task-bottom {
  bottom: 0px;
  border: 1px solid #d2d2d2;
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

// -------------------------------------
.thumbnail {
  width: 100%;
  height: 800px;
  // background: cornflowerblue;
}

.ctn {
  margin-left: 20px;
}

.ctn-box {
  margin-top: 10px;
  width: 98%;
  height: 50px;
  border-radius: 5px;
  background: rgb(245, 244, 244);
  display: flex;
  align-items: center;
}

.ctn-box-list {
  // margin-top: 10px;
  width: 98%;
  height: 630px;
  border-radius: 5px;
  border: 1px solid #ddd;
  // padding: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ctn-box-list-list {
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 10px;
  margin-top: 10px;
  width: 242px;
  height: 285px;

  position: relative;
  cursor: pointer;
  border-radius: 3px;
}

.list-bottom {
  color: #337ab7;
  font-size: 15px;
  background: white;
  padding: 2px 5px 2px 5px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.list-bottom1 {
  bottom: 30px;
  left: 3px;
  position: absolute;
  color: rgb(85, 189, 90);
  font-size: 20px;
}
</style>
